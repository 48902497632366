import { defineMessages } from 'react-intl'

/* Form constants */
export const FORMS = {
    APPLICATION_ADD: 'application-add',
    APPLICATION_UPDATE: 'application-update',
    DOMAIN_POINTER_EDIT: 'domain-pointer-edit',
    DOMAIN_POINTER_ADD: 'domain-pointer-add',
    DOMAIN_POINTER_DELETE: 'domain-pointer-delete',
    USER_SETTINGS: 'user-settings',
}

export const ADD_APPLICATION_FORM_NAME = 'add-application'
export const DELETE_APPLICATION_FORM_NAME = 'delete-application'
export const ADD_DATABASE_FORM_NAME = 'add-database'
export const EDIT_DATABASE_FORM_NAME = 'edit-database'
export const DELETE_DATABASE_FORM_NAME = 'delete-database'
export const DELETE_DATABASE_USER_FORM_NAME = 'delete-database-user'
export const ADD_FTP_USER_FORM_NAME = 'add-ftp-user'
export const EDIT_FTP_USER_FORM_NAME = 'edit-ftp-user'
export const REMOVE_FTP_USER_FORM_NAME = 'remove-ftp-user'
export const ADD_SSL_CERTIFICATE_FORM_NAME = 'add-ssl-certificate'
export const EDIT_SSL_CERTIFICATE_FORM_NAME = 'edit-ssl-certificate'
export const DELETE_SSL_CERTIFICATE_FORM_NAME = 'delete-ssl-certificate'
export const ADD_EDIT_SSH_KEY_FORM_NAME = 'add-edit-ssh-key'
export const DELETE_SSH_KEY_FORM_NAME = 'delete-ssh-key'
export const ADD_EDIT_SSH_USER_FORM_NAME = 'add-edit-ssh-user'
export const DELETE_SSH_USER_FORM_NAME = 'delete-ssh-user'

export const FORM_DOMAIN_POINTER_ADD = 'domain-pointer-add'
export const FORM_DOMAIN_POINTER_DELETE = 'domain-pointer-delete'

/* Wizard constants */
export const WIZARD_DOMAIN_POINTER_ADD = 'add-domain-pointer'
export const WIZARD_DOMAIN_POINTER_EDIT = 'edit-domain-pointer'
export const WIZARD_DOMAIN_ADD = 'add-domain'

/* Details list constants */
export const DETAILS_LIST_DOMAIN_POINTER = 'domain-pointer'
export const DETAILS_LIST_ACTIVITY_LOG = 'server-change'
export const DETAILS_LIST_FTP_USERS = 'ftp-user'
export const DETAILS_LIST_SSH_USERS = 'ssh-user'
export const DETAILS_LIST_DNS_ZONES = 'dns-zones'
export const DETAILS_LIST_NOTIFICATIONS_RULES = 'notifications-rules'

export const USER_TYPES = {
    NEW: 'new',
    EXISTING: 'existing',
}

export const SERVER_TYPES = {
    APPLICATION: 'application',
    BALANCER: 'balancer',
    DATABASE: 'database',
}

export const CERTIFICATE_TYPES = {
    NONE: 'none',
    LETS_ENCRYPT: 'lets_encrypt',
    NEW: 'new',
    EXISTING: 'existing',
}

export const CONTROL_TYPES = {
    EDIT: 'pencil',
    DOWNLOAD: 'download',
    DASHBOARD: 'dashboard',
    SETTINGS: 'settings',
    DATABASE: 'database',
    USERS: 'users',
    DATA_STATS: 'data-stats',
    BACK_UPS: 'back-ups',
    REMOVE: 'remove',
    SSL: 'ssl',
    DOMAIN_POINTERS: 'domain-pointers',
    INTEGRATION_TEST: 'integration-test',
    PERFORMANCE_SCAN: 'performance-scan',
    CHECKLIST: 'checklist',
    VIEW_ON_WEB: 'view-on-web',
    SAVE: 'save',
    BACK: 'undo',
}

/* Date constants */

// Moment.js ll equivalent
export const DATE = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

// Moment.js lll equivalent
export const DATE_TIME = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}

// Moment.js llll equivalent
export const DATE_TIME_WEEKDAY = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}

export const STATUS = {
    SUCCESS: 'good',
    WARNING: 'warn',
    ERROR: 'error',
    CRITICAL: 'critical',
    SKIPPED: 'skipped',
    PENDING: 'pending',
    EXCEPTION: 'exception',
    QUEUE: 'queue',
}

export const CHECKLIST_TEST_STATUS = STATUS

export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export const FTP_USERNAME_PATTERN = /^[a-zA-Z0-9_@.]+$/
export const SSH_USERNAME_PATTERN = /^[a-zA-Z0-9_]*$/
export const DATABASE_USERNAME_PATTERN = /^[A-Za-z0-9@#$%^&+!=*()_{},.;:<>?/\\[\\]|'\\\\"`~-]+$/
export const DATABASE_NAME_PATTERN = /^[a-zA-Z0-9_]+$/
export const DOMAIN_POINTER_PATTERN = /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i
export const DOMAIN_PATTERN = /^(?!www\.)[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i
export const SSH_KEY_PATTERN = /^[\S]+$/

export const PASSWORD_PATTERN_ACCOUNT =
    '^(?=[A-Za-z0-9@#$%^&+!=*()_{},.;:<>?/\\[\\]|\'\\\\"`~-]+$)^(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&+!=*()_{},.;:<>?/\\[\\]|\'\\\\"`~-])(?=.{7,}).*$'
export const PASSWORD_PATTERN_ACCOUNT_GENERATED = '^[a-zA-Z0-9!@#$%&_]{25}$'
export const PASSWORD_PATTERN_DATABASE = '^[a-zA-Z0-9!@#$%&_]{10,50}$'
export const PHONE_NUMBER_PATTERN_ACCOUNT = /^[0-9]+$/i

// Translations
export const CHECKLIST_GROUP_LABELS = defineMessages({
    GENERAL: {
        id: 'ChecklistCategoryLabel::general',
        defaultMessage: 'General',
    },
    SECURITY: {
        id: 'ChecklistCategoryLabel::security',
        defaultMessage: 'Security',
    },
    MAGENTO: {
        id: 'ChecklistCategoryLabel::magento',
        defaultMessage: 'Magento',
    },
    SEO: {
        id: 'ChecklistCategoryLabel::seo',
        defaultMessage: 'SEO',
    },
    EMAIL: {
        id: 'ChecklistCategoryLabel::email',
        defaultMessage: 'Email',
    },
})

export const CHECKLIST_GROUPS = [
    {
        title: 'algemeen',
        label: CHECKLIST_GROUP_LABELS.GENERAL,
    },
    {
        title: 'beveiliging',
        label: CHECKLIST_GROUP_LABELS.SECURITY,
    },
    {
        title: 'seo',
        label: CHECKLIST_GROUP_LABELS.SEO,
    },
    {
        title: 'magento',
        label: CHECKLIST_GROUP_LABELS.MAGENTO,
    },
    {
        title: 'email',
        label: CHECKLIST_GROUP_LABELS.EMAIL,
    },
]

// graphql polling intervals (in milliseconds)
export const POLLINTERVAL = {
    MONITORING: 1 * 60 * 1000,
    ACTIVITY_LOG: 1 * 20 * 1000,
    CHECKLIST: 1 * 20 * 1000,
    NOTIFICATIONS: 10 * 1000,
}

export const APPLICATION_LIST_LIMIT = 100

export const SSL_STATUS = {
    EXPIRED: 'expired',
    WARNING: 'warning',
    INFO: 'info',
    VALID: 'valid',
}

export const INVOICE_RATING = {
    GOOD: 90,
    NEUTRAL: 25,
    BAD: 0,
}

export const TICKETS_PAGE_SIZE = 10
export const RECORDS_PAGE_SIZE = 20

export const TICKET_STATUS_TYPES = {
    ANY: 'any',
    OPEN: 'open',
    PENDING: 'pending',
    SOLVED: 'solved',
    CLOSED: 'closed',
}

export const RESPONSE_MESSAGES = {
    ACCESS_DENIED: 'Access Denied.',
}

export const HIPEX_ORGANIZATION_IGNORED_HEADER_KEY =
    'hipex::organization-ignored'
export const HIPEX_ORGANIZATION_HEADER_KEY = 'hipex::organization'
